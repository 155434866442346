$dark-color: #a1a8b8;
$dark-background: #323741;
$dark: #2A2D35;
$light-background: #F2F4F7;

body, #root {
  width: 100%;
  height: 100%;
}

.container {
  padding: 30px;
  background: $light-background;

  &.dark {
    background: $dark;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 0px;
  }
  .header {
    padding: 20px 30px !important;
  }
}

.header {
  margin-bottom:20px;
  padding: 20px 0px;
  background: transparent;
}

.block {
  background: #FFF;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  height:570px;
  text-align: center;
  position: relative;

  &.dark {
    background: $dark-background;
  }
}

.card {
 background: #FFF !important;
 text-align: center;
 box-shadow: 0 0.05rem 0.01rem rgba(75,75,90,.075);

  &.dark {
    background: $dark-background !important;
    border : none;
  }
}

.title {
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 30px !important;
  text-align: left;

  &.dark {
    color: $dark-color;
  }
}

.indicator-title {
  color: #6c757d !important;
  text-align: center;
  text-transform: capitalize;

  &.dark {
    color: $dark-color !important;
  }
}

.indicator {
  font-size: 35px;
  font-weight: bold;
}

.second-indicator {
  margin-top: 16px;
  font-size: 17px;
  font-weight: bold;
}

@media only screen and (max-width: 1020px) {
  .second-indicator {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1020px) {
  .indicator {
    font-size: 29px;
  }
}

.footer {
  margin-top:20px;
  background: transparent;
  padding: 20px 0px !important;

  &.dark {
    color: $dark-color;
    background: transparent;
  }
}

.text {
  color: #000000;

  &.dark {
    color: $dark-color;
  }
}

// Table

.table-row-dark {
  color: $dark-color;
  background: $dark;
}

.table-column-dark {
  color: $dark-color !important;
  background: $dark !important;
}

// Pagination

.pagination-dark {
  a {
    color:$dark-color !important;
  }
}

.table-dark .ant-pagination-item-active {
  background-color: $dark !important;
}

.table-dark .ant-pagination-item-ellipsis {
  color: $dark-color !important;
}

// Select Dark Mode

.select.dark .ant-select-selector {
  background-color: $dark-background !important;
  border: none !important;
  color: $dark-color;
  font-weight: bold;
}

.select.dark .ant-select-arrow {
  color: $dark-color !important;
}

.center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

// Message

.message-primary {
  margin-top:25px;
  font-size: 20px;
  font-weight: bold;
  color: #000000;

  &.dark {
    color: $dark-color;
  }
}

.message-secondary {
  margin-top:15px;
  color: grey;
  font-size:15px;

  &.dark {
    color: $dark-color;
  }
}

// Article

.article {
  text-align: left;

  h4 a {
    font-size: 16px;
  }

  &.dark {
    h4 a {
      color: lightcyan !important;

      &:hover {
        color: #1890ff !important;
      }
    }

    .ant-list-item-meta-description {
      color: $dark-color !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  #map-block {
    height: auto;
  }
}

.ant-empty {
  width:90%;
}
